
.contact {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  color: white;
}
.contactContainer {
  border-radius: 10px;
  width: 50%;
  margin-top: 5%;
  margin-bottom: 10%;
  padding: 4%;
  background: rgba(0,0,0, 0.5);
}

.message-sent {
  padding: 20px;
  margin-bottom: 10px;
  text-align: center;
}
.contact-title {
  margin-bottom: 50px;
}

.paragraph {
  margin-bottom: 5px;
  padding: 0;
  color: white;
}

.imgContainer {
  margin-right: 20px;
}

.contactForm {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-content: center;
}
.contactInfo {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.contactInput {
  border-radius: 5px;
  width: 70%;
  padding: 5px;
  background-color: hsl(210, 9%, 96%);
  margin: 0 auto;
  margin-bottom: 15px;
  font-family: 'Epilogue', sans-serif;
  font-size: 15px;
  font-weight: 900;
  animation: inputFull 1000ms ease;
}
.contactBox {
  border-radius: 5px;
  width: 70%;
  padding: 5px;
  margin: 0 auto;
  margin-bottom: 25px;
  background-color: hsl(210, 9%, 96%);
  font-family: 'Epilogue', sans-serif;
  font-family: 15px;
  font-weight: 900;
}
.contactText {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.submitButton {
  width: 200px;
  margin-bottom: 30px;
  margin: 0 auto;
  padding: 10px;
  border-radius: 5px;
  background-color: rgb(74, 163, 151);
  border: none;
  color: white;
  font-family: 'Epilogue', sans-serif;
}
.submitButton:hover {
  background-color: rgba(100, 100, 100, 0.2);
  border: solid rgb(74, 163, 151);
  color: white;
  cursor: pointer;
  transition: 400ms;
}

.personal-email-container {
  margin-top: 20px;
}
.email:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 1024px) {
  .contactContainer {
    margin-bottom: 20%;
  }
}

@media only screen and (max-width: 768px) {
  .contactContainer {
    margin-top: 10%;
    margin-bottom: 10%;
    width: 90%;
  }
  .submitButton:hover {
    background-color: rgb(74, 163, 151);
  }
}

@media only screen and (max-width: 414px) {
  .contactInput {
    width: 80%;
  }
  .contactBox {
    width: 80%;
  }
}

@media only screen and (max-width: 375px) {
  .contactContainer {
    width: 100%;
  }
  .contact-title {
    padding-left: 20px;
  }
  .contactBox {
    width: 80%;
  }
  .contactInput {
    width: 80%;
  }
  .contactText {
    flex-flow: column nowrap;
  }
  .submitButton:hover {
    background-color: rgb(74, 163, 151);
  }

}


.attr-visible {
  position: fixed;
  overflow: hidden;
  bottom: 0;
  width: 100%;
  height: 20%;
  margin: 0 auto;
  background-color: white;
}

.attr-not-visible {
  position: fixed;
  overflow: hidden;
  bottom: 0;
  width: 100%;
  height: 10%;
  margin: 0 auto;
  background-color: white;
}

.footer-container {
  padding-top: 30px;
  font-size: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: flex-end;
}

.footer-link:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 1024px) {
  .attr-visible {
    height: 10%;
  }
}
@media only screen and (max-width: 768px) {
  .attr-visible {
    height: 15%;
  }
}
@media only screen and (max-width: 450px) {
  .attr-visible {
    height: 23%;
  }
  .attr-not-visible {
    height: 15%;
  }
}
@media only screen and (max-width: 375px) {
  .attr-visible {
    height: 15%;
    width: 100%;
  }
  .footer-container {
    padding-top: 20px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 320px) {
  .attr-visible {
    height: 23.5%;
  }
}

@keyframes topToBottomFadeIn {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.home-container {
  text-align: center;
  padding-top: 20%;
  display: flex;
  flex-flow: column nowrap;
  color: white;
}

.self {
  height: 30%;
  border-radius: 50% 50%;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 1;
  top: 11%;
  box-shadow: 5px 5px 50px 10px #888888;
}

.title-container {
  background-color: rgb(255,255,255, 0.3);
  width: 100%;
  padding: 60px 0;
  margin: auto;
  animation: topToBottomFadeIn 900ms ease-in-out;
}

.title {
  animation: topToBottomFadeIn 1500ms ease-out;
}

.title-name{
  font-size: 40px;
  letter-spacing: 5px;
}
.title-role{
  font-size: 20px;
  width: 30%;
  margin: auto;
}

.iconContainer {
  display: flex;
  justify-content: center;

}
.svg-icon {
  width: 30px;
  height: 100%;
  padding-right: 15px;
}

.description-container {
  margin: auto;
  width: 50%;
  font-size: 100%;
}

.language-icons-container {
  background-color: rgb(220, 220, 220);
  height: 100%;
  width: 95%;
  margin: 0 auto;
  margin-top: 50px;
  padding: 30px 0 30px 0;
  border-radius: 10px;
  transition: width 900ms 500ms, border-radius 900ms 300ms;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
.icon {
  font-size: 50px;
  transition: font-size 800ms 100ms;
  line-height: 60px;
}
.icon:hover {
  font-size: 520%;
}

.language-icons-container:hover {
  width: 100%;
  border-radius: 0px;
}
@media only screen and (max-width: 1024px) {
  .home-container {
    padding-top: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .home-container {
    padding-top: 50%;
  }
  .title-role {
    width: 100%;
  }
  .description-container {
    width: 90%;
  }
  .language-icons-container {
    width: 100%;
    border-radius: 0px;
    justify-content: space-between;
  }
  .icon {
    font-size: 200%;
  }
  .icon:hover {
    font-size: 200%;
  }
}
@media only screen and (max-width: 375px) {
  .home-container {
    padding-top: 50%;
  }
  .self {
    top: 5%;
  }
  .title-name {
    width: 100%;
    font-size: 200%;
  }
  .title-role {
    width: 100%;
  }
  .description-container {
    width: 100%;
  }
  .language-icons-container {
    width: 100%;
    border-radius: 0px;
  }
  .icon:hover {
    font-size: 50px;
  }
  .attribution {
    font-size: 5%;
  }
}
@media only screen and (max-width: 320px) {

  .title-name {
    font-size: 160%;
    width: 100%;
  }
  .title-role {
    font-size: 90%;
  }
  .icon {
    font-size: 45px;
  }
  .icon:hover {
    font-size: 45px;
  }
}

.portfolioContainer {
  padding-top: 60px;
}

.projectContainer {
  margin-bottom: 5%;
}
/* fixes footer hidding content */
.clear {
  clear: both;
  height: 80px;
}
@media only screen and (max-width: 1024px) {
  .projectContainer {
    margin-bottom: 20%;
  }
}
@media only screen and (max-width: 768px) {
  .projectContainer {
    margin-bottom: 25%;
  }
}

@media only screen and (max-width: 375px) {
  .projectContainer {
    margin-bottom: 20%;
  }
  .clear {
    height: 120px;
  }
}
@import url(https://fonts.googleapis.com/css2?family=Epilogue&display=swap);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  color: black;
  background-image: url(/static/media/dan-calderwood-4elUg_nmH4Y-unsplash.c4bf92ed.jpg);
  height: 100%;
  width: 100;
}

/* changes font to all child component */
h1, p, a{
  font-family: 'Epilogue', sans-serif;
}

a{
  text-decoration: none;
  color: black;
}
@-webkit-keyframes topToBottomFadeIn {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes topToBottomFadeIn {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.home-container {
  text-align: center;
  padding-top: 20%;
  display: flex;
  flex-flow: column nowrap;
  color: white;
}

.self {
  height: 30%;
  border-radius: 50% 50%;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 1;
  top: 11%;
  box-shadow: 5px 5px 50px 10px #888888;
}

.title-container {
  background-color: rgb(255,255,255, 0.3);
  width: 100%;
  padding: 60px 0;
  margin: auto;
  -webkit-animation: topToBottomFadeIn 900ms ease-in-out;
          animation: topToBottomFadeIn 900ms ease-in-out;
}

.title {
  -webkit-animation: topToBottomFadeIn 1500ms ease-out;
          animation: topToBottomFadeIn 1500ms ease-out;
}

.title-name{
  font-size: 40px;
  letter-spacing: 5px;
}
.title-role{
  font-size: 20px;
  width: 30%;
  margin: auto;
}

.iconContainer {
  display: flex;
  justify-content: center;

}
.svg-icon {
  width: 30px;
  height: 100%;
  padding-right: 15px;
}

.description-container {
  margin: auto;
  width: 50%;
  font-size: 100%;
}

.language-icons-container {
  background-color: rgb(220, 220, 220);
  height: 100%;
  width: 95%;
  margin: 0 auto;
  margin-top: 50px;
  padding: 30px 0 30px 0;
  border-radius: 10px;
  transition: width 900ms 500ms, border-radius 900ms 300ms;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
.icon {
  font-size: 50px;
  transition: font-size 800ms 100ms;
  line-height: 60px;
}
.icon:hover {
  font-size: 520%;
}

.language-icons-container:hover {
  width: 100%;
  border-radius: 0px;
}
@media only screen and (max-width: 1024px) {
  .home-container {
    padding-top: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .home-container {
    padding-top: 50%;
  }
  .title-role {
    width: 100%;
  }
  .description-container {
    width: 90%;
  }
  .language-icons-container {
    width: 100%;
    border-radius: 0px;
    justify-content: space-between;
  }
  .icon {
    font-size: 200%;
  }
  .icon:hover {
    font-size: 200%;
  }
}
@media only screen and (max-width: 375px) {
  .home-container {
    padding-top: 50%;
  }
  .self {
    top: 5%;
  }
  .title-name {
    width: 100%;
    font-size: 200%;
  }
  .title-role {
    width: 100%;
  }
  .description-container {
    width: 100%;
  }
  .language-icons-container {
    width: 100%;
    border-radius: 0px;
  }
  .icon:hover {
    font-size: 50px;
  }
  .attribution {
    font-size: 5%;
  }
}
@media only screen and (max-width: 320px) {

  .title-name {
    font-size: 160%;
    width: 100%;
  }
  .title-role {
    font-size: 90%;
  }
  .icon {
    font-size: 45px;
  }
  .icon:hover {
    font-size: 45px;
  }
}

.portfolioContainer {
  padding-top: 60px;
}

.projectContainer {
  margin-bottom: 5%;
}
/* fixes footer hidding content */
.clear {
  clear: both;
  height: 80px;
}
@media only screen and (max-width: 1024px) {
  .projectContainer {
    margin-bottom: 20%;
  }
}
@media only screen and (max-width: 768px) {
  .projectContainer {
    margin-bottom: 25%;
  }
}

@media only screen and (max-width: 375px) {
  .projectContainer {
    margin-bottom: 20%;
  }
  .clear {
    height: 120px;
  }
}
@-webkit-keyframes button-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes button-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
.dataContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  width: 50%;
  height: 400px;
  margin: 0 auto;
  position: relative;
  border-radius: 5%;
}

.portfolioImg {
  border-radius: 10px;
  width: 80%;
  transition: width 1000ms ease 200ms;
}
.dataContainer:hover .portfolioImg{
  width: 100%;
  opacity: 0.6;
}
.dataContainer:hover .textContainer {
  opacity: 1;
}
.dataContainer:hover .portfolio-button{
  -webkit-animation: button-focus-in 500ms both;
          animation: button-focus-in 500ms both;
}

.textContainer {
  text-align: center;
  opacity: 0;
  position: absolute;
  font-size: 90%;
  transition: 500ms;
  font-weight: bolder;
  color: white;
}
.portfolio-button-container {
  margin-top: 50px;
}

.portfolio-button {
  font-size: 15px;
  margin-right: 20px;
  background: none;
  width: 100px;
  height: 50px;
  color: white;
  border: solid 1px white;
  border-radius: 5px;
}

.portfolio-button:hover {
  background-color: rgb(74, 163, 151, 0.7);
  font-size: 20px;
  transition: 500ms;
}
@media only screen and (max-width: 1024px) {
  .dataContainer {
    width: 70%;
  }
  .portfolioImg {
    width: 70%;
  }
  .textContainer {
    font-size: 140%;
  }
  .portfolio-button {
    background-color: rgb(74, 163, 151, 0.7);
    font-size: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .dataContainer {
    width: 100%;
  }
  .portfolioImg {
    width: 80%;
  }
  .textContainer {
    font-size: 130%;
    width: 90%;
  }
  .portfolio-button {
    background-color: rgb(74, 163, 151, 0.7);
    font-size: 20px;
  }
}

@media only screen and (max-width: 375px) {
  .dataContainer {
    flex-flow: column wrap;
    width: 100%;
    height: 80%;
  }

  .portfolioImg {
    width: 80%;
    padding-top: 10%;
    padding-bottom: 5%;
  }
  .portfolio-button {
    background-color: rgb(74, 163, 151, 0.7);
    font-size: 20px;
  }
  .textContainer {
    width: 90%;
    font-size: 80%;
  }
}



.contact {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  color: white;
}
.contactContainer {
  border-radius: 10px;
  width: 50%;
  margin-top: 5%;
  margin-bottom: 10%;
  padding: 4%;
  background: rgba(0,0,0, 0.5);
}

.message-sent {
  padding: 20px;
  margin-bottom: 10px;
  text-align: center;
}
.contact-title {
  margin-bottom: 50px;
}

.paragraph {
  margin-bottom: 5px;
  padding: 0;
  color: white;
}

.imgContainer {
  margin-right: 20px;
}

.contactForm {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-content: center;
}
.contactInfo {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.contactInput {
  border-radius: 5px;
  width: 70%;
  padding: 5px;
  background-color: hsl(210, 9%, 96%);
  margin: 0 auto;
  margin-bottom: 15px;
  font-family: 'Epilogue', sans-serif;
  font-size: 15px;
  font-weight: 900;
  -webkit-animation: inputFull 1000ms ease;
          animation: inputFull 1000ms ease;
}
.contactBox {
  border-radius: 5px;
  width: 70%;
  padding: 5px;
  margin: 0 auto;
  margin-bottom: 25px;
  background-color: hsl(210, 9%, 96%);
  font-family: 'Epilogue', sans-serif;
  font-family: 15px;
  font-weight: 900;
}
.contactText {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.submitButton {
  width: 200px;
  margin-bottom: 30px;
  margin: 0 auto;
  padding: 10px;
  border-radius: 5px;
  background-color: rgb(74, 163, 151);
  border: none;
  color: white;
  font-family: 'Epilogue', sans-serif;
}
.submitButton:hover {
  background-color: rgba(100, 100, 100, 0.2);
  border: solid rgb(74, 163, 151);
  color: white;
  cursor: pointer;
  transition: 400ms;
}

.personal-email-container {
  margin-top: 20px;
}
.email:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 1024px) {
  .contactContainer {
    margin-bottom: 20%;
  }
}

@media only screen and (max-width: 768px) {
  .contactContainer {
    margin-top: 10%;
    margin-bottom: 10%;
    width: 90%;
  }
  .submitButton:hover {
    background-color: rgb(74, 163, 151);
  }
}

@media only screen and (max-width: 414px) {
  .contactInput {
    width: 80%;
  }
  .contactBox {
    width: 80%;
  }
}

@media only screen and (max-width: 375px) {
  .contactContainer {
    width: 100%;
  }
  .contact-title {
    padding-left: 20px;
  }
  .contactBox {
    width: 80%;
  }
  .contactInput {
    width: 80%;
  }
  .contactText {
    flex-flow: column nowrap;
  }
  .submitButton:hover {
    background-color: rgb(74, 163, 151);
  }

}


.attr-visible {
  position: fixed;
  overflow: hidden;
  bottom: 0;
  width: 100%;
  height: 20%;
  margin: 0 auto;
  background-color: white;
}

.attr-not-visible {
  position: fixed;
  overflow: hidden;
  bottom: 0;
  width: 100%;
  height: 10%;
  margin: 0 auto;
  background-color: white;
}

.footer-container {
  padding-top: 30px;
  font-size: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: flex-end;
}

.footer-link:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 1024px) {
  .attr-visible {
    height: 10%;
  }
}
@media only screen and (max-width: 768px) {
  .attr-visible {
    height: 15%;
  }
}
@media only screen and (max-width: 450px) {
  .attr-visible {
    height: 23%;
  }
  .attr-not-visible {
    height: 15%;
  }
}
@media only screen and (max-width: 375px) {
  .attr-visible {
    height: 15%;
    width: 100%;
  }
  .footer-container {
    padding-top: 20px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 320px) {
  .attr-visible {
    height: 23.5%;
  }
}

.visible {
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.attribute-container{
  padding-top: 1%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-content: flex-end;
  background-color: rgb(74, 163, 151);
}

.not-visible {
  display: none;
}

.attribution {
  margin: 0px 0px 10px 20px;
  font-size: 20%;
  color: black;
}
.copyright {
  padding-left: 1%;
  width: 100%;
  background-color: rgb(74, 163, 151);
  font-size: 20%;
  color: black;
}

@media only screen and (max-width: 375px) {
  .attribution {
    font-size: 1%;
    margin: 0;
    padding-left: 1%;
  }
}
